@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

/* * {
  outline: 1px solid red;
} */

body {
  /*@apply scrollbar-thin scrollbar-thumb-primary scrollbar-track-dark;*/

  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  div,
  span {
    cursor: default;
  }
}

body::-webkit-scrollbar {
  width: 6px; /* Width of scrollbar */
  height: 4px; /* Height of horizontal scrollbar */
}

body::-webkit-scrollbar-thumb {
  background-color: #2a2a2a; /* Dark gray thumb */
  border-radius: 10px; /* Rounded edges */

  transition: all 0.3s ease;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #3ac7ff; /* Slightly brighter on hover */
}

body::-webkit-scrollbar-track {
  background-color: #0f172a; /* Gray track */
}

.slider-container {
  position: relative;
  width: 100%;
  margin: 5px 0;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 4px;
  background: #374151;
  outline: none;
  cursor: pointer;
  transition: background 0.3s;
}

.slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: linear-gradient(
    to right,
    #3ac7ff 0%,
    #3ac7ff var(--slider-value),
    #374151 var(--slider-value),
    #374151 100%
  );
  border-radius: 4px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 22px;
  height: 22px;
  background: #fff;
  border: 8px solid #3ac7ff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
  transition: transform 0.2s;

  margin-top: -8px;
}

.slider::-webkit-slider-thumb:hover {
  transform: scale(1.2);
}

.slider-markings {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  font-size: 12px;
  color: #9ca3af;
}

.slider-mark {
  flex: 1;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.slider-value {
  margin-top: 10px;
  font-size: 16px;
  color: #3ac7ff;
  text-align: center;
}
